import { render, staticRenderFns } from "./BehaviourConfiguration.vue?vue&type=template&id=3bd27902&scoped=true&"
import script from "./BehaviourConfiguration.vue?vue&type=script&lang=js&"
export * from "./BehaviourConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./BehaviourConfiguration.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./BehaviourConfiguration.vue?vue&type=style&index=1&id=3bd27902&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd27902",
  null
  
)

export default component.exports